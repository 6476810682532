import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {apiPartnerRentalDetail} from "../../../common/api/partner/rental/apiPartnerRentalDetail";
import {actionValidateGiveItemAction} from "../../../common/actions/actionValidateGiveItemAction";
import {apiPartnerMutationCreateExchange} from "../../../common/api/partner/mutation/apiPartnerMutationCreateExchange";
import {actionChangeCategoryMutation} from "../../../common/actions/actionChangeCategoryMutation";
import {
    apiParnterMutationCreateCategories
} from "../../../common/api/partner/mutation/apiPartnerMutationCreateCategories";
import {actionGiveItem} from "../../../common/actions/actionGiveItem";
import {
    formatRentalItemsToCategoryMutations
} from "../../../common/helpers/format/formatRentalItemsToCategoryMutations";
import {
    apiPartnerAvailabilityPerCategory
} from "../../../common/api/partner/availability/apiPartnerAvailabilityPerCategory";
import moment from "moment";
import {apiPartnerRentalAddDeposit} from "../../../common/api/partner/rental/apiPartnerRentalAddDeposit";
import {resetUnlinkItemModal} from "../../modals/unlinkItemModal/unlinkItemModalSlice";
import {resetServiceModal} from "../../../../views/serviceModal/serviceModalSlice";
import {formatRentalContainerToRentalItems} from "../../../common/helpers/format/formatRentalContainerToItems";

export const loadRentalItemsExchange = createAsyncThunk(
    'rentalItemsExchange/load',
    async (rentalUuid, {getState}) => {

        try {
            const rentalDetailResponse = await apiPartnerRentalDetail(rentalUuid)
            const rentalContainer = rentalDetailResponse?.data?.items

            const rentalStartDateMoment = moment(rentalContainer?.rental.dateRentStart)

            const currentMoment = moment()
            let startDate
            if (rentalStartDateMoment.isBefore(currentMoment)) {
                startDate = currentMoment.format('YYYY-MM-DD HH:mm')
            } else {
                startDate = rentalStartDateMoment.format('YYYY-MM-DD HH:mm')
            }

            const availabilityResponse = await apiPartnerAvailabilityPerCategory({visibleInPartnerEnvironment: true}, {
                startDate: startDate,
                endDate: moment(rentalContainer?.rental.dateRentEnd).format('YYYY-MM-DD HH:mm'),
                rentalId: rentalContainer.rental.uuid
            })

            const availabilityAccessoireResponse = await apiPartnerAvailabilityPerCategory({visibleInPartnerEnvironment: true}, {
                startDate: startDate,
                endDate: moment(rentalContainer?.rental.dateRentEnd).format('YYYY-MM-DD HH:mm'),
                type: 'accessoire',
                rentalId: rentalContainer.rental.uuid
            })

            return {
                rentalContainer: rentalContainer,
                rentalItems: formatRentalContainerToRentalItems(rentalContainer),
                categoryAvailabilities: [...availabilityResponse.data.items, ...availabilityAccessoireResponse.data.items]
            }

        } catch (e) {
            console.log(e)
        }
    }
)

export const submitRentalItemsExchange = createAsyncThunk(
    'rentalItemsExchange/submit',
    async (_, {getState}) => {
        try {
            const {rentalItemsExchangePage} = getState();

            // Request category mutations
            if (rentalItemsExchangePage.rentalContainer) {


                // Request exchange
                await apiPartnerMutationCreateExchange({
                    giveItems: rentalItemsExchangePage.rentalGiveItems,
                    takeItems: rentalItemsExchangePage.rentalTakeItems,
                    rentalUuid: rentalItemsExchangePage.rentalContainer.rental.uuid
                })


                if (rentalItemsExchangePage.categories.length) {

                    await apiParnterMutationCreateCategories({
                        categoryMutations: rentalItemsExchangePage.categories.filter((category) => category.quantity !== 0),
                        rentalUuid: rentalItemsExchangePage.rentalContainer.rental.uuid
                    })

                }

                // Recalculate deposit... should be in exchange endpoint though
                if (!!rentalItemsExchangePage.rentalContainer.rental.depositEnabled) {
                    await apiPartnerRentalAddDeposit(rentalItemsExchangePage.rentalContainer.rental.uuid)
                }

            }

        } catch (e) {
            console.log(e)
        }
    }
)

export const giveItem = createAsyncThunk(
    'rentalItemsExchange/giveItem',
    async (data, {getState, dispatch, rejectWithValue}) => {

        const {item, scanned} = data

        try {
            const {rentalItemsExchangePage} = getState();

            // Reset modals
            dispatch(resetUnlinkItemModal())
            dispatch(resetServiceModal())

            const categoryMutation = rentalItemsExchangePage.categoryMutations?.find((categoryMutation) => categoryMutation.categoryId === item.availabilityCategory.id)
            const orderline = rentalItemsExchangePage.rentalContainer.rentalItems?.find((orderLine) => orderLine.categoryId === item.availabilityCategory.id)

            const quantity = categoryMutation ? categoryMutation.quantity : orderline ? orderline.quantity : 1

            // Remove if already inside <-> else add to array

            const actionResult = actionGiveItem({
                item: item,
                rentalItems: rentalItemsExchangePage.rentalItems,
                rentalGiveItems: rentalItemsExchangePage.rentalGiveItems,
                occupiedItems: rentalItemsExchangePage.occupiedItems,
                serviceItems: rentalItemsExchangePage.serviceItems,
                scanned: !!scanned
            })

            // Increase categoryMutation number
            dispatch(setCategoryMutation({
                quantity: quantity + actionResult.categoryMutationQuantity,
                categoryId: item.availabilityCategory.id
            }))

            return actionResult

        } catch (e) {
            console.log(e)
        }


    }
)

export const takeItem = createAsyncThunk(
    'rentalItemsExchange/takeItem',
    async (payload, {getState, dispatch, rejectWithValue}) => {

        const {item} = payload

        console.log(item)

        const {rentalItemsExchangePage} = getState()
        let rentalTakeItems = [...rentalItemsExchangePage.rentalTakeItems]

        // Only take items if it is one of the rentalItems
        if (rentalItemsExchangePage.rentalItems.find((rentalItem) => rentalItem.id === item.id)) {


            const categoryMutation = rentalItemsExchangePage.categoryMutations?.find((categoryMutation) => categoryMutation.categoryId === item.availabilityCategory.id)
            const orderline = rentalItemsExchangePage.rentalContainer.rentalItems?.find((orderLine) => orderLine.categoryId === item.availabilityCategory.id)

            const quantity = categoryMutation ? categoryMutation.quantity : orderline.quantity


            // Remove if already inside <-> else add to array
            if (rentalTakeItems?.find(takeItem => takeItem.id === item.id)) {

                rentalTakeItems = rentalTakeItems.filter((takeItem) => takeItem.id !== item.id)

                // Increase categoryMutation number
                dispatch(setCategoryMutation({
                    quantity: quantity + 1,
                    categoryId: item.availabilityCategory.id
                }))

            } else {

                // Decrease categoryMutation number
                dispatch(setCategoryMutation({
                    quantity: quantity - 1,
                    categoryId: item.availabilityCategory.id
                }))

                rentalTakeItems.push(item)

            }
        }

        return rentalTakeItems

    }
)


export const validateGiveItems = createAsyncThunk(
    'rentalItemsExchange/validateGiveItems',
    async (_, {getState, dispatch, rejectWithValue}) => {

        try {

            const {rentalItemsExchangePage} = getState()

            const data = await actionValidateGiveItemAction({
                rentalContainer: rentalItemsExchangePage.rentalContainer,
                rentalGiveItems: rentalItemsExchangePage.rentalGiveItems,
                rentalTakeItems: rentalItemsExchangePage.rentalTakeItems,
                rentalItems: rentalItemsExchangePage.rentalItems,
                categoryMutations: rentalItemsExchangePage.categoryMutations,
                rentalGiveItemNotifications: rentalItemsExchangePage.rentalGiveItemNotifications,
                submitOption: rentalItemsExchangePage.submitOption,
                categories: rentalItemsExchangePage.categoryAvailabilities
            })

            data.removedItems?.forEach((item) => {

                const categoryMutation = rentalItemsExchangePage.categoryMutations?.find((categoryMutation) => categoryMutation.categoryId === item.availabilityCategory.id)
                const orderline = rentalItemsExchangePage.rentalContainer.rentalItems?.find((orderLine) => orderLine.categoryId === item.availabilityCategory.id)

                const quantity = categoryMutation ? categoryMutation.quantity : orderline.quantity

                // Decrease categoryMutation number
                dispatch(setCategoryMutation({
                    quantity: quantity - 1,
                    categoryId: item.availabilityCategory.id
                }))

            })

            return data

        } catch (e) {
            console.log(e)
        }
    }
)

export const setCategoryMutation = createAsyncThunk(
    'rentalItemsExchange/setCategoryMutation', (categoryMutation, {getState}) => {

        try {
            const {rentalItemsExchangePage} = getState()

            return actionChangeCategoryMutation({
                categoryMutation: categoryMutation,
                rentalContainer: rentalItemsExchangePage.rentalContainer,
                categoryMutationsState: rentalItemsExchangePage.categoryMutations
            })
        } catch (e) {
            console.log(e)
        }

    }
)

const initialState = {
    loading: false,
    step: 'take',
    rentalItems: [],
    rentalTakeItems: [],
    rentalGiveItems: [],
    occupiedItems: [],
    serviceItems: [],
    categoryMutations: [],
    categories: [],
    rentalGiveItemNotifications: [],
    categoryAvailabilities: [],
    giveItemValidation: {
        processing: false,
        completed: false,
        tooManyCategoriesGiven: false,
        notAllCategoriesGiven: false
    },
    submitOption: 'swap',
    submitting: false
}

export const rentalItemsExchangePageSlice = createSlice({
    name: 'rentalItemsExchange',
    initialState,
    reducers: {
        changeStep: (state, action) => {

            // Reset give items on take step
            if (action.payload === 'take') {
                state.rentalGiveItems = []
            }

            state.step = action.payload

        },
        setSubmitOption: (state, action) => {
            state.submitOption = action.payload
        },
        formatCategories: (state) => {

            if (state.rentalContainer) {
                state.categories = formatRentalItemsToCategoryMutations({
                    rentalOrderLines: state.rentalContainer.rentalItems,
                    categoryMutations: state.categoryMutations,
                    rentalTakeItems: state.rentalTakeItems,
                    rentalGiveItems: state.rentalGiveItems,
                    rentalItems: state.rentalItems,
                    compensationData: {
                        categoryAvailabilities: state.categoryAvailabilities,
                        rentalContainer: state.rentalContainer,
                    }
                })
            }

        },
    },
    extraReducers: (builder) => {

        builder.addCase(loadRentalItemsExchange.pending, (state) => {
            Object.assign(state, initialState)
            state.loading = true
        })

        builder.addCase(loadRentalItemsExchange.fulfilled, (state, action) => {
            if (action.payload) {
                state.rentalContainer = action.payload.rentalContainer
                state.rentalItems = action.payload.rentalItems
                state.categoryAvailabilities = action.payload.categoryAvailabilities
                state.rentalGiveItems = []
                state.rentalTakeItems = []
                state.loading = false
            }
        })

        builder.addCase(giveItem.pending, (state) => {
            state.rentalGiveItemNotifications = []
        })

        builder.addCase(giveItem.fulfilled, (state, action) => {

            if (action.payload) {

                state.rentalGiveItems = action.payload.rentalGiveItems
                state.occupiedItems = action.payload.occupiedItems
                state.serviceItems = action.payload.serviceItems
            }

        })

        builder.addCase(takeItem.fulfilled, (state, action) => {
            if (action.payload) {
                state.rentalTakeItems = action.payload
            }
        })
        builder.addCase(setCategoryMutation.fulfilled, (state, action) => {

            if (action.payload) {
                state.categoryMutations = action.payload.categoryMutations
            }

        })

        builder.addCase(validateGiveItems.pending, (state) => {

            state.giveItemValidation = {
                ...state.giveItemValidation,
                processing: true,
                completed: false,
            }

        })
        builder.addCase(validateGiveItems.fulfilled, (state, action) => {

            if (action.payload) {
                state.giveItemValidation = action.payload.giveItemValidation
                state.rentalGiveItems = action.payload.rentalGiveItems
                state.rentalGiveItemNotifications = action.payload.rentalItemNotifications
            }

        })
        builder.addCase(submitRentalItemsExchange.pending, (state) => {
            state.submitting = true
        })
        builder.addCase(submitRentalItemsExchange.fulfilled, (state) => {
            Object.assign(state, initialState)
        })
    },
})

export const {
    changeStep,
    setSubmitOption,
    formatCategories
} = rentalItemsExchangePageSlice.actions

export const rentalItemsExchangePageReducer = rentalItemsExchangePageSlice.reducer
